import web_app_1 from './web_app_1.png';
import iOS_1 from './iOS_1.png';
import groceryList_1 from './groceryList_1.png';
import head_elipse from './head_elipse.png';
import './App.css';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


function App() {

  return (
    <div className="App">
      <header className="App-header">

        <h1>Tom He</h1>
        <div id="bubble">
          <div class="talk-bubble tri-right border round btm-left-in">
            <div class="talktext">
              <p>Hey, scroll down to learn more about me.</p>
            </div>
          </div>
        </div>
      </header>

      <div><img id="pfp" src={head_elipse} /></div>
      <Section
        name="About Me"
        type="text"
        content={<>Hi, my name is <strong>Tom He</strong> and I am coming from a background in <strong>pharmacy</strong>. After a few years of work, I decided to make the switch to being a developer. I have always enjoyed working on hard problems even when I get stuck for a long time. This, combined with the fact that I have always wanted to do something more tech-related, made the decision to change career paths feel natural. I am learning a variety of languages and frameworks both in and out of school. My other hobbies include playing sports and learning all sorts of wierd things.</>}
      />
      <TimelineSection
        name="Education"
        date1="September 2023 - Present"
        heading1="Computer Systems Technology Diploma | BCIT"
        date2="January - April 2023"
        heading2="CPSC 110 - Computation, Programs, and Programming | The University of British Columbia"
        date3="September 2014 - May 2018"
        heading3="Bachelor of Science in Pharmacy | The University of British Columbia"
      />
      <TimelineSection
        name="Work Experience"
        date1="November 2023 - Present"
        heading1="Relief Pharmacist | Rexall Pharmacy"
        date2="May 2019 - September 2023"
        heading2="Pharmacy Manager | Low Cost Western Pharmacy #2"
        date3="February - May 2019"
        heading3="Pharmacist | Little Mountain Pharmacy"
        date4="January - May 2019"
        heading4="Pharmacist | Pharmasave Shannon Mews"
      />
      <Section
        name="Projects"
        type="slideshow"

      />
      <Footer>

      </Footer>
    </div>
  );
}

function Section({ name, type, content }) {
  if (type == "text") {
    return (
      <div><h1>{name}</h1>
        <Paragraph
          stuff={content}
        />
      </div>);
  } else if (type == "slideshow") {
    return (
      <div>
        <h1 class="heading">{name}</h1>
        <div id="slideContainer">
          <Carousel />
        </div>
      </div>);
  }

}






function Carousel() {
  const [index, setIndex] = useState(0);

  const carousels = [
    <Slide
      image={web_app_1}
      title={"Study Spaces at BCIT"}
      description={"For our projects course, we created an application web-based mobile application using html/css/javascript with firebase on the backend. It was designed to help students find study areas on BCIT campus. Over the course of the term, we used worked as a team of 3 using agile methodology to create the application."}
    />,
    <Slide
      image={iOS_1}
      title={"iReflex"}
      description={"Using Swift and SwiftUI, I made a basic game to track reaction speed."}
    />,
    <Slide
      image={groceryList_1}
      title={"GroceryList++"}
      description={"To familiarize myself with using C++, I made a grocery list application that can add, delete and lookup items. There is also added functionality for saving lists in JSON and the reading those files."}
    />
  ];;

  const onNext = () => {
    console.log(carousels.length);

    if (index < carousels.length - 1) {
      let i = index + 1;
      setIndex(i);
    } else {
      setIndex(0);
    }
  };

  const onPrev = () => {
    if (index > 0 && index < carousels.length) {
      let i = index - 1;
      setIndex(i);
    } else {
      setIndex(carousels.length - 1);
    }
  };

  return (
    <div className="carousel">
      <i class="arrow left" onClick={onPrev}></i>
      <i class="arrow right" onClick={onNext}></i>
      {carousels.map((item, i) =>
        i === index ? (
          <div className="carousel-item" key={i}>
            {item}
          </div>
        ) : null
      )}
    </div>
  );
};

function TimelineSection({ name, date1, heading1, description1, date2, heading2, description2, date3, heading3, description3, date4, heading4, description4 }) {
  return (
    <div>
      <h1>{name}</h1>
      <TimelineEntry
        date={date1}
        heading={heading1}
        description={description1}
      />
      <TimelineEntry
        date={date2}
        heading={heading2}
        description={description2}
      />
      <TimelineEntry
        date={date3}
        heading={heading3}
        description={description3}
      />
      <TimelineEntry
        date={date4}
        heading={heading4}
        description={description4}
      />
    </div>);
}

function Paragraph({ stuff }) {
  return (<p>{stuff}</p>);
}

function TimelineEntry({ date, heading, description }) {
  if (date == undefined) {
    return "";
  } else {
    return (
      <div class="timelineContainer">
        <div class="date"><h3>{date}</h3></div>
        <div class="line">
          <div class="dot"></div>
          <div class="content">
            <h3><b>{heading}</b></h3>
            <p>{description}</p>
          </div>
        </div>
      </div>);
  }
}

function Slide({ image, title, description }) {
  return (
    <div class="">
      <img class="projectImage" src={image} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

function Footer() {
  return (
    <div class="footerContainer">
      <div class="row">
        <a class="footerLink" href="http://github.com/tom-bcit">
          <FontAwesomeIcon icon={faGithub} />
          <span
            style={{
              marginLeft: "10px",
            }}
          >
            Github
          </span>
        </a>
        <a class="footerLink" href="http://linkedin.com/in/tom-he-350523243">
          <FontAwesomeIcon icon={faLinkedin} />
          <span
            style={{
              marginLeft: "10px",
            }}
          >
            Linkedin
          </span>
        </a>
        <a class="footerLink" href="mailto:the18@my.bcit.ca">
          <FontAwesomeIcon icon={faEnvelope} />
          <span
            style={{
              marginLeft: "10px",
            }}
          >
            Email
          </span>

        </a>

      </div>
    </div>
  );
};

export default App;
